<template>
    <div class="h-screen dark:bg-gray-900 bg-white text-black dark:text-white flex justify-center flex-col">
        <div class="sm:px-5 xl:px-24  xl:container mx-auto sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3">
            <h1 class="text-4xl mb-5">Изменение пароля <span class="text-sm px-2 pb-1 text-blue-400"
                                                             v-tooltip="tooltip"><i class="fas fa-info "></i></span>
            </h1>
            <div class="dark:bg-gray-800 dark:shadow-none shadow-2xl rounded p-5">
                <small v-if="message.length" class="text-green-300">{{ message }}</small>
                <input placeholder="Пароль" v-model="password" type="password">
                <small class="text-red-600"
                       v-if="!$v.password.required && $v.password.$dirty">{{ $t('forms.validation.required') }}</small>
                <small class="text-red-600"
                       v-if="!$v.password.minLength && $v.password.$dirty">{{
                        $t('forms.validation.passMinLength')
                    }}</small>
                <input placeholder="Повторите пароль" v-model="confirm" type="password">
                <small class="text-red-600"
                       v-if="!$v.confirm.required && $v.confirm.$dirty">{{ $t('forms.validation.required') }}</small>
                <small class="text-red-600"
                       v-if="!$v.confirm.sameAsPassword">{{ $t('forms.validation.sameAsPassword') }}</small>

                <div class="flex justify-center mt-2">
                    <button class="dark:bg-green-400 bg: px-10 py-2 rounded" @click.prevent="submit">Обновить
                    </button>
                </div>


            </div>
        </div>
        <div class="flex py-5 justify-center">
            <router-link tag="a" :to="{name:'login'}"
                         class="text-black dark:text-green-400  dark:hover:text-blue-300 hover:text-blue-400">Войти
            </router-link>
        </div>

    </div>
</template>

<script>
import {sameAs, required, minLength} from "vuelidate/lib/validators";
import {validationMixin} from 'vuelidate'
import axios from "axios";

export default {
    meta: {
        title: 'Обновление пароля'
    },
    name: "ChangePassword",
    data: () => ({
        tooltip: 'Пароль должен содержать латинские символы верхнего и нижнего регистра (A-z).\n' +
            'Пароль должен содержать знаки пунктуации (,.<>/?;:\'"[]{}\\|`~!@#$%^&*()-_+=).\n',
        password: '',
        confirm: '',
        message: ''
    }),
    methods: {
        submit() {
            let id = this.$route.query.ID
            let checkword = this.$route.query.CHECKWORD
            if (!id || !checkword) {
                this.$notify({
                    title: 'Ошибка',
                    text: 'Отсутствуют необходимые параметры',
                    type: 'error'
                })
            }
            let payload = {
                id: id, checkword: checkword, password: this.password, confirm: this.confirm
            }
            this.$v.$touch()
            if (!this.$v.$invalid) {
                axios.post('https://declarant.by/rest/user/password/update', payload)
                    .then(res => {
                        this.message = res.data.data
                        this.$notify({
                            title: 'Успешно',
                            text: res.data.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        this.message = err.response.data.message
                        this.$notify({
                            title: 'Ошибка',
                            text: err.response.data.message,
                            type: 'error'
                        })
                    })
            }
        }
    },
    mixins: [validationMixin],
    validations: {
        password: {
            required,
            minLength: minLength(10)
        },
        confirm: {
            required,
            sameAsPassword: sameAs('password')
        }
    }

}
</script>

<style scoped>
input[type=password]{
    @apply text-gray-700 placeholder-gray-600 w-full px-4 py-2.5 mt-2
    transition duration-500 ease-in-out transform border-transparent
    rounded bg-gray-200
    dark:focus:bg-gray-200 outline-none;
}
</style>